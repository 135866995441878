
  import { Component, Watch } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { plainToInstance } from 'class-transformer'
  import { fixPrice, parseToNumber, updateNestedObject } from '@/utils/general'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import { Payment, PaymentOrder, PaymentOrderItem, PaymentRecipient, PaymentType } from '@/entities/finance'
  import { Deal } from '@/entities/crm'
  import { Form } from '@/entities/public/Resource/metadata'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { Person, PersonAccount } from '@/entities/persons'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import CustomDialog from '@/components/forms/fields/CustomDialog.vue'
  import { ExpenseView } from '@/components/forms/view/ExpenseView'
  import _ from 'lodash'
  import { PurchaseOrder } from '@/entities/purchase'
  import { Expense } from '@/entities/settings'
  import { Debounce } from '@/utils/decorators'

@Component({
  components: { CustomDialog, FieldTitle, GFiles, GAlert, GDatePicker, LinkedPerson, BaseForm },
  methods: { fixPrice },
  computed: {},
})
  export default class ExpensesForm extends ExpenseView {
  paymentRecipient: PaymentRecipient = plainToInstance(PaymentRecipient, {})
  backupPaymentRecipient: PaymentRecipient = plainToInstance(PaymentRecipient, {})
  paymentOrderItem: PaymentOrderItem = plainToInstance(PaymentOrderItem, {})
  purchaseOrder: PurchaseOrder = plainToInstance(PurchaseOrder, {})
  paymentOrder: PaymentOrder = plainToInstance(PaymentOrder, {})
  payment: Payment = plainToInstance(Payment, {})
  deal: Deal = plainToInstance(Deal, {})
  disabledPerson = false
  types = ['debt_payment', 'traffic_ticket_payment', 'retention']
  disabledSearch = false
  title = 'Gastos'
  subtitle = 'Gestiona de forma sencilla y rápida las solicitudes de pago'
  errorAccount = 'No se encontraron cuentas bancarias para este beneficiario'
  errorRut = 'No se encontró el RUT del beneficiario'
  errorMail = ''
  errorMissingAccount = ''
  errorPayment = ''
  errorPaymentType = ''
  isLoading = true
  idProcess = null
  expenses: Expense[] = []
  accounts: PersonAccount[] = []
  paymentType: PaymentType[] = []
  showDetail = false
  paymentAlert = {
    open: false,
    title: '¿Desea anular el gasto?',
  }

  formData = {
    expenseType: null,
    beneficiary: null,
    expense: null,
    paymentType: null,
    payments: [],
    account: null,
    uniquePayment: null,
    comment: '',
    file: [],
  }

  declare $refs: {
    form: HTMLFormElement
    fieldBeneficiary: LinkedPerson,
    formPayment: HTMLFormElement,
  };

  metadata = {}
  metadataCollection = {
    lead: null,
    deal: null,
    payment: null,
  }

  fields = {
    backup: {
      properties: {
        required: true,
        properties: {
          label: 'Respaldo',
          rules: this.fieldRequired,
          multiple: true,
          accept: [],
          fileTypeId: null,
          name: '',
          required: false,
        },
      },
    },
    beneficiary: {
      properties: {
        required: true,
        properties: {
          label: 'Beneficiario',
          rules: this.fieldRequired,
          itemText: 'formInfo',
          required: true,
          itemValue: 'id',
          clearable: true,
          returnObject: true,
        },
      },
      rules: this.fieldRequired,
      items: [],
    },
    expense: {
      properties: {},
    },
  }

  async mounted () {
    this.expenses = await this.fetchData({
      query: { name: 'find', model: 'Expense' },
      filter: { type: { name: { _eq: 'auto_purchase' } } },
    })

    await this.setMetadata()

    const { uid, parentId } = this
    if (!isNaN(parentId) && parentId) {
      await this.getInfo(parentId)
    }

    if (!isNaN(uid)) {
      await this.getExpenseInfo(uid.toString())
    }

    await this.setTheBackup()
    await this.checkPaymentType()
    this.setDetails()
    this.isLoading = false
  }

  async checkPaymentType () {
    let filter
    if (this.deal?.lead?.pipeline) {
      const isRenewal = this.deal?.lead?.pipeline?.name === 'renewal' || this.deal?.lead?.pipeline?.name === 'financed_renovation'

      filter = isRenewal ? { name: { _in: ['electronic_transfer', 'ok_view', 'vehicle_partially_paid'] } } : { name: { _in: ['electronic_transfer', 'ok_view'] } }
    } else {
      if (this.payment.deal?.idLead) {
        const lead = await this.fetchData({
          query: { name: 'fetchLite', model: 'Lead', params: { id: this.payment.deal?.idLead } },
          force: true,
        })
        const isRenewal = lead?.pipeline?.name === 'renewal' || lead?.pipeline?.name === 'financed_renovation'

        filter = isRenewal ? { name: { _in: ['electronic_transfer', 'ok_view', 'vehicle_partially_paid'] } } : { name: { _in: ['electronic_transfer', 'ok_view'] } }
      }
    }

    this.paymentType = await this.fetchData({
      query: { name: 'find', model: 'PaymentType' },
      filter,
    })
  }

  async getExpenseInfo (id) {
    this.payment = await this.fetchData({
      query: { name: 'fetch', model: 'Payment', params: { id } },
      force: true,
    })

    const files = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { parameter: { process: { id: { _eq: this.idProcess } } } },
        ],
      },
      force: true,
    })
    this.formData.file = files?.filter(file => file.parameter.name === 'spending_support') || []

    this.paymentRecipient = await this.fetchData({
      query: { name: 'fetch', model: 'PaymentRecipient', params: { id: this.payment.idPaymentRecipient } },
      force: true,
    })

    this.formData.expenseType = this.expenses.find(expense => expense.name === this.paymentRecipient?.paymentOrderItem?.name)
    this.backupPaymentRecipient = _.cloneDeep(this.paymentRecipient)
    this.purchaseOrder.id = this.paymentRecipient?.paymentOrderItem?.order?.idProcessRecord
    this.purchaseOrder = await this.fetchData({
      query: { name: 'fetch', model: 'PurchaseOrder', params: { id: this.purchaseOrder.id } },
      force: true,
    })
    this.paymentOrder = await this.fetchData({
      query: { name: 'find', model: 'PaymentOrder' },
      filter: { id_process_record: { _eq: this.purchaseOrder.id } },
      force: true,
    })
    this.formData.beneficiary = this.paymentRecipient.person
    this.formData.expense = this.payment.amount
    this.formData.payments = _.cloneDeep(this.paymentRecipient?.payments)
    this.formData.paymentType = this.payment?.type
    this.formData.uniquePayment = this.payment
    this.formData.account = this.payment?.recipientAccount
    this.formData.comment = this.payment?.comment

    const deal = await this.fetchData({
      query: { name: 'find', model: 'Deal' },
      filter: { payment_orders: { recipients: { id: { _eq: this.payment.idPaymentRecipient } } } },
      force: true,
    })
    this.paymentRecipient.paymentOrderItem.order.deal = deal
    this.paymentOrderItem = _.cloneDeep(this.paymentRecipient.paymentOrderItem)
    this.deal = deal[0]
  }

  async setMetadata () {
    const { metadata } = this.getForm('Lead', 'lead')
    const { metadata: metadataPayment } = this.getForm('Payment', 'expenses')
    this.metadataCollection.lead = metadata

    const { form: { title }, fields } = metadataPayment as Form
    this.title = title
    this.metadataCollection.deal = metadataPayment
    Object.assign(this.fields.beneficiary.properties, fields.beneficiary.properties)
    this.fields.expense.properties = fields.expense.properties

    const { uid } = this

    if (!this.isBreadCrumbPresent(this.title)) {
      this.setFormCrumbs(metadataPayment, 'Gasto', Boolean(!isNaN(uid)))
    }

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'payment' } },
    })

    this.idProcess = process[0].id

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { name: { _eq: 'spending_support' } }] },
    })
    this.setProperties(fileInfo, 'spending_support', 'backup')
  }

  async getInfo (parentId) {
    const { purchaseorder } = await this.getParentData('PurchaseOrder', parentId.toString())
    this.purchaseOrder = purchaseorder
    const { negotiation } = await this.getParentData('Negotiation', purchaseorder.negotiation.id)
    const { deal } = await this.getParentData('Deal', negotiation.inspection.appraisal.deal.id.toString())
    this.deal = deal
    if (!this.paymentRecipient?.id) {
      const order = await this.fetchData({
        query: { name: 'find', model: 'PaymentOrder' },
        filter: { id_process_record: { _eq: parentId } },
        force: true,
      })

      this.paymentRecipient.paymentOrderItem = order?.[0]?.items?.[0]
      if (this.paymentRecipient?.paymentOrderItem?.order) {
        this.paymentRecipient.paymentOrderItem.order = order?.[0]
      }
    }
    if (this.paymentRecipient?.paymentOrderItem?.order) {
      this.paymentRecipient.paymentOrderItem.order.deal = [deal]
    }
    this.setDetails()
  }

  async setTheBackup () {
    const { backup, paymentRecipient, uid, parentId } = this
    if (!backup?.purchaseForm && !paymentRecipient?.id) {
      return this.close()
    }
    if (!backup) {
      return
    }

    const isPurchaseForm = 'purchaseForm' in backup && _.cloneDeep(backup.purchaseForm)
    const isExpenseForm = 'expenseForm' in backup && backup.expenseForm
    const isBeneficiary = 'beneficiary' in backup && backup.beneficiary
    const isNegotiation = 'negotiation' in backup && backup.negotiation

    if (isPurchaseForm) {
      if (isPurchaseForm?.expenses) {
        this.fields.beneficiary.items = isPurchaseForm?.owners?.filter(owner => isPurchaseForm?.expenses?.payments?.some(payment => payment?.person?.id === owner))
      } else {
        this.fields.beneficiary.items = isPurchaseForm?.owners
      }

      if (this?.paymentRecipient && isPurchaseForm?.lastOrder) {
        this.paymentRecipient.paymentOrderItem = isPurchaseForm?.lastOrder?.items?.[0]
        this.paymentRecipient.paymentOrderItem.amount = parseToNumber(isPurchaseForm?.price)
      }
    }

    if (isExpenseForm) {
      this.fields.beneficiary.items = [isExpenseForm.beneficiary]
      this.formData.expense = isExpenseForm.expense
      this.formData.beneficiary = isBeneficiary || isExpenseForm.beneficiary
      this.formData.expenseType = isExpenseForm.expenseType
    }

    if (isNaN(uid) && isNaN(parentId) && isNegotiation) {
      await this.setNegotiation(isNegotiation, isPurchaseForm)
    }
  }

  async setNegotiation (negotiation, purcharseForm) {
    const { deal } = await this.getParentData('Deal', negotiation.inspection.appraisal.deal.id.toString())
    this.deal = deal
    if (!purcharseForm?.lastOrder) {
      const paymentRecipient = plainToInstance(PaymentRecipient, {
        paymentOrderItem: plainToInstance(PaymentOrderItem, {
          order: plainToInstance(PaymentOrder, {}),
          amount: purcharseForm.price,
        }),
      })
      paymentRecipient.paymentOrderItem.order.deal = [deal]
      this.paymentRecipient = paymentRecipient
    } else {
      this.paymentRecipient.paymentOrderItem.order = purcharseForm?.lastOrder
      this.paymentRecipient.paymentOrderItem.order.deal = [deal]
    }

    const { backup } = this
    const isEditPayment = 'editPayment' in backup && backup.editPayment

    if (isEditPayment) {
      this.formData.beneficiary = isEditPayment.person
      this.formData.account = isEditPayment.account
      this.formData.expense = isEditPayment.amount
      this.formData.paymentType = isEditPayment.paymentType
    }

    if (purcharseForm?.price) {
      this.paymentRecipient.paymentOrderItem.amount = parseToNumber(purcharseForm?.price)
    }

    this.setDetails()
  }

  async goPerson () {
    const { formData, paymentRecipient } = this
    this.saveBackup()
    const idPayment = paymentRecipient?.id ? paymentRecipient?.id.toString() : 'created'
    const idPerson = formData?.beneficiary?.id || 'created'
    await this.$router.push({
      name: 'generic-person-nested',
      params: { model: 'PaymentRecipient', uid: idPayment, id: idPerson },
    })
  }

  saveBackup () {
    const { backup, formData, payment } = this

    if (backup) {
      backup.expenseForm = { ...formData, payment }
      backup.beneficiary = null
      this.setBackup(backup)
    } else {
      this.setBackup({ expenseForm: { ...formData, payment } })
    }
  }

  validateForm () {
    const { errorPayment, errorRut, errorMail, formData: { paymentType } } = this

    if (paymentType?.name === 'ok_view') {
      this.errorAccount = ''
    } else {
      this.errorPaymentType = ''
    }
    return Boolean(errorPayment?.length) ||
      Boolean(this.errorAccount?.length) ||
      Boolean(this.errorPaymentType?.length) ||
      Boolean(errorRut?.length) ||
      Boolean(errorMail?.length)
  }

  async send () {
    const available = parseToNumber(this.paymentRecipient?.expenseDetails.available)

    this.setPaymentError(available)
    const beneficiary = this.$refs?.fieldBeneficiary
    const isVehiclePartiallyPaid = this.isVehiclePartiallyPaid

    if (!this.$refs.form.validate() || (!isVehiclePartiallyPaid && !beneficiary?.$refs.form.validate()) || this.validateForm()) {
      return
    }
    if (this.formData?.paymentType?.isOkView) {
      this.formData.paymentType = this.paymentType.find(type => type.name === 'ok_view')
    } else if (!this.isNotBankData || this.types.includes(this.formData.expenseType?.name)) {
      this.formData.paymentType = this.paymentType.find(type => type.name === 'electronic_transfer')
    }
    this.loadingForm = true
    const { paymentRecipient } = this

    if (paymentRecipient.id || paymentRecipient?.paymentOrderItem?.order?.id) {
      await this.updatePaymentRecipient(this)
    }

    this.saveBackup()
    await this.close()
  }

  @Debounce(1000)
  setDetails () {
    const { metadataCollection, paymentRecipient, deal, backup, formData, uid, purchaseOrder, paymentOrder } = this
    const isPurchaseForm = backup ? 'purchaseForm' in backup && backup.purchaseForm : null

    if (paymentRecipient?.paymentOrderItem) {
      paymentRecipient.paymentOrderItem.amount = purchaseOrder?.agreedAmount || parseToNumber(isPurchaseForm?.price)
    }

    paymentRecipient.purchaseOrder = paymentOrder?.[0]?.id ? { expenses: { payments: paymentOrder?.[0].payments } } : isPurchaseForm

    paymentRecipient.expense = formData.expense - parseToNumber(paymentRecipient?.purchaseOrder?.expenses?.payments?.find(pay => pay.id === uid)?.amount || 0)
    const available = parseToNumber(paymentRecipient?.expenseDetails.available)
    this.setPaymentError(available)

    this.metadata = {
      data: paymentRecipient,
      metadata: metadataCollection.deal,
    }

    this.showDetail = Boolean(deal.id)
  }

  get bindBeneficiary () {
    const { fields: { beneficiary }, handleBeneficiaryError } = this

    if (!beneficiary) return {}

    return { ...beneficiary.properties, items: beneficiary.items, errorMessages: handleBeneficiaryError }
  }

  async searchPerson ({ input }) {
    if (!input?.length || input?.length < 2 || this.disabledSearch) return

    const { metadataCollection: { lead }, paymentRecipient } = this

    if (!lead) return
    const { fields } = lead
    const query = updateNestedObject(fields.client.computed.queries.items.where, '_eq', input, ['name_person_type'])

    const items = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { ...query },
      force: true,
    })

    this.fields.beneficiary.items = paymentRecipient?.person?.id ? items?.filter(item => paymentRecipient?.person?.id !== item.id) : items
  }

  get change () {
    const { formData } = this

    return JSON.stringify(formData)
  }

  get isNotBankData () {
    const { accounts } = this
    return !accounts.length
  }

  get isRetention () {
    const { formData } = this

    return formData?.expenseType?.name === 'retention'
  }

  get isFile () {
    const { formData } = this
    return formData?.expenseType?.name === 'traffic_ticket_payment' || formData?.expenseType?.name === 'debt_payment'
  }

  get isNotDebt () {
    const { formData } = this

    return formData?.expenseType?.name !== 'debt_payment' && formData?.expenseType?.name !== 'traffic_ticket_payment' && formData?.expenseType?.name !== 'retention'
  }

  get isVehiclePartiallyPaid () {
    const { formData } = this
    return formData?.paymentType?.name === 'vehicle_partially_paid'
  }

  get handleBeneficiaryError () {
    const { errorRut, errorMissingAccount, errorMail } = this

    const errors = [errorRut, errorMail, errorMissingAccount].filter(Boolean)
    return errors.join(', ')
  }

  get isElectronicSelected () {
    const { formData, isNotBankData, payment } = this

    if (this.types.includes(formData.expenseType?.name)) {
      if (payment?.id) {
        this.disabledPerson = true
      }
      return false
    }
    return formData?.paymentType?.name === 'electronic_transfer' || (!isNotBankData && !formData?.paymentType?.name)
  }

  @Watch('formData.beneficiary', { immediate: true, deep: true })
  async onBeneficiaryChange (val: Person) {
    this.errorPaymentType = ''
    if (val?.type?.name === 'system') {
      this.errorMail = ''
      this.paymentRecipient.person = val
      this.setDetails()
      return
    }
    if (!this.isLoading) {
      this.formData.paymentType = null
    }

    if (this.formData?.account?.id !== this.payment?.recipientAccount?.id && !this.isLoading) {
      this.formData.account = null
    }

    if (val?.id) {
      this.checkData(val)
      await this.checkAccount(val.id)
    } else {
      this.errorMissingAccount = ''
      this.errorAccount = ''
      this.errorRut = ''

      if (!this.isLoading) {
        this.formData.paymentType = null
      }

      if (!this.isLoading) {
        this.formData.account = null
      }
      this.errorPaymentType = ''
      this.errorPayment = ''
    }

    if (this.paymentRecipient) {
      this.paymentRecipient.person = val
    }
  }

  setPaymentError (val) {
    if (val < 0) {
      this.errorPayment = `El monto del pago no puede ser mayor al monto total del gasto.`
    } else {
      this.errorPayment = ''
    }
  }

  async checkAccount (id) {
    const { formData: { paymentType } } = this

    if (paymentType?.name === 'ok_view') return

    const accounts = await this.fetchData({
      query: { name: 'find', model: 'PersonAccount' },
      filter: { _and: [{ id_person: { _eq: id } }, { active: { _eq: true } }] },
      force: true,
    })

    if (!accounts.length && !this.disabledSearch) {
      this.errorMissingAccount = 'Indicar cuenta bancaria'
    } else {
      this.errorMissingAccount = ''
    }
    this.accounts = accounts

    if (this.accounts.length) {
      this.formData.paymentType = this.paymentType.find(type => type.name === 'electronic_transfer')
    }
  }

  checkData (val) {
    if (this.disabledSearch) return
    this.errorRut = !val?.uid ? 'El rut es obligatorio' : ''
    this.errorMail = !val?.email?.personal ? `El correo es obligatorio` : ''
  }

  @Watch('isNotBankData', { immediate: true })
  onBankDataChange (val) {
    if (val && !this.isLoading) {
      this.formData.paymentType = null
      this.formData.account = null
    }
  }

  @Watch('formData.account', { immediate: true })
  onAccountChange (val) {
    if (val) {
      const { formData: { uniquePayment }, backup } = this

      const isSameAccount = uniquePayment?.recipientAccount?.id === val.id
      if (!isSameAccount) {
        const items = this.paymentRecipient?.paymentOrderItem

        const existAccount = items?.paymentRecipientsTitle?.some(payment => payment?.account?.id === val.id)
        this.setAccountError(existAccount)
      }
      const isPurchaseForm = backup && 'purchaseForm' in backup && backup.purchaseForm
      const isEditPayment = backup && 'editPayment' in backup && backup.editPayment
      if (isPurchaseForm) {
        const { expenses } = isPurchaseForm
        if (expenses?.length) {
          const existAccount = expenses?.some(expense => {
            if (uniquePayment?.id) {
              return expense?.account?.id === val.id && expense.id !== uniquePayment?.id
            }
            if (!isEditPayment) {
              return expense?.account?.id === val.id
            }
          })
          this.setAccountError(existAccount)
        }
      }
    }
  }

  setAccountError (val) {
    if (val) {
      this.errorAccount = 'La cuenta seleccionada tiene un gasto en proceso, editelo'
    } else {
      this.errorAccount = ''
    }
  }

  @Watch('formData.paymentType', { immediate: true })
  async onPaymentTypeChange (val) {
    if (val?.name === 'ok_view') {
      const { formData: { uniquePayment, beneficiary } } = this
      const isSameType = uniquePayment?.type?.name === val.name
      const item = this.paymentRecipient?.paymentOrderItem
      const existPaymentType = item?.paymentRecipientsTitle?.some(payment => payment?.paymentType?.name === val.name && payment.person.id === beneficiary.id)

      if (existPaymentType && !isSameType) {
        this.errorPaymentType = 'Este beneficiario tiene un vale vista pendiente, editelo'
      } else {
        this.errorPaymentType = ''
      }
      this.errorAccount = ''
    } else if (val?.name === 'vehicle_partially_paid') {
      const resp = await this.checkVehiclePartiallyPaid()
      if (!resp) {
        const person = await this.fetchData({
          query: { name: 'find', model: 'Person' },
          filter: { type: { name: { _eq: 'system' } } },
        })
        this.formData.beneficiary = person?.[0]
      }
    }
  }

  async checkVehiclePartiallyPaid () {
    if (!this.backup) return
    const lead = this.backup?.negotiation?.inspection?.appraisal?.deal?.lead
    const deals = await this.fetchData({
      query: { name: 'findLite', model: 'Deal' },
      filter: { id_lead: { _eq: lead.id } },
      force: true,
    })
    const sale = deals?.find(deal => deal.isSale)

    const saleOrders = await this.getStockSalesById(sale.stock.id)

    if (saleOrders?.length && !saleOrders?.some(saleOrder => saleOrder.id === sale?.order?.id)) {
      this.alert.open = true
      this.alert.title = `No se puede generar el gasto porque el vehículo ya se encuentra en la venta ${saleOrders.map(sale => sale.id)} `
      return false
    }
  }

  findPos (recipient, paymentRecipient) {
    return recipient?.id === paymentRecipient?.id && !isNaN(recipient?.id)
  }

  removePersonBackup () {
    const { backup } = this
    if (backup) {
      backup.beneficiary = null
      this.setBackup(backup)
    }
  }

  setProperties (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  confirmAction () {
    this.alert.open = false
    this.alert.title = ''
    this.formData.paymentType = null
    this.formData.beneficiary = null
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  get disabled () {
    return this.payment?.status?.isToUpdating
  }

  @Watch('formData', { immediate: true, deep: true })
  onPaymentChange (val) {
    if (!isNaN(val?.expense)) {
      const { payment, paymentRecipient, backup } = this

      const isEditPayment = backup ? 'editPayment' in backup && backup.editPayment : null
      const isPurchaseForm = backup ? 'purchaseForm' in backup && backup.purchaseForm : null
      const recipients = isPurchaseForm?.expenses?.items?.[0]?.recipients || paymentRecipient?.paymentOrderItem?.recipients

      const localPosSearch = recipients?.findIndex(recipient => this.findLocalPos(recipient, val))
      const pos = localPosSearch !== -1 ? localPosSearch : recipients?.findIndex(recipient => this.findPos(recipient, paymentRecipient)) ?? -1

      if (!recipients?.length && backup?.purchaseForm?.lastOrder?.items?.[0]?.recipients?.length) {
        this.paymentRecipient.paymentOrderItem = backup?.purchaseForm?.lastOrder?.items?.[0]
        this.paymentRecipient.paymentOrderItem.amount = parseToNumber(backup?.purchaseForm?.price)
      }

      if (pos !== -1 && !isNaN(pos) && payment?.id) {
        const posPayment = paymentRecipient?.paymentOrderItem?.recipients[pos]?.payments?.findIndex(_ => _.id === payment.id)
        if (posPayment !== -1) {
          this.paymentRecipient.paymentOrderItem.recipients[pos].payments[posPayment].amount = parseToNumber(val?.expense)
        }
      }

      if (!recipients?.length && !isEditPayment && Boolean(paymentRecipient?.paymentOrderItem?.amount)) {
        this.setPaymentError(parseToNumber(paymentRecipient?.paymentOrderItem?.amount) - parseToNumber(val?.expense))
      }
      this.setDetails()
    }
  }

  @Watch('formData.expenseType', { immediate: true, deep: true })
  async onExpenseTypeChange (val) {
    let persons = []
    if (val?.name === 'debt_payment') {
      persons = await this.fetchData({
        query: { name: 'find', model: 'Person' },
        filter: { company_type: { name: { _eq: 'financial' } } },
        limit: 100,
      })
      this.disabledSearch = true
      this.formData.beneficiary = null
      this.errorMissingAccount = ''
    } else if (val?.name === 'traffic_ticket_payment' || val?.name === 'retention') {
      persons = await this.fetchData({
        query: { name: 'find', model: 'Person' },
        filter: { type: { name: { _eq: 'system' } } },
      })
      this.formData.beneficiary = persons[0]
      this.disabledPerson = true
      this.disabledSearch = true
      this.errorMissingAccount = ''
    } else {
      const isPurchaseForm = this.backup && 'purchaseForm' in this.backup && _.cloneDeep(this.backup.purchaseForm)

      if (isPurchaseForm) {
        if (isPurchaseForm?.expenses) {
          persons = isPurchaseForm?.owners?.filter(owner => isPurchaseForm?.expenses?.payments?.some(payment => payment?.person?.id === owner))
        } else {
          persons = isPurchaseForm?.owners
        }
      } else {
        persons = []
      }
      this.disabledSearch = false
      this.disabledPerson = false
    }
    this.fields.beneficiary.items = persons
  }

  async cancelExpense () {
    this.paymentAlert.open = true
  }

  async confirmCancel () {
    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { status: { name: { _eq: 'closed' } } }] },
    })

    const filterSuccess = {
      _and: [
        { type: { name: { _eq: 'canceled' } } },
        { status: { process: { table_name: { _eq: 'payment' } } } },
      ],
    }

    const closingReason = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: filterSuccess,
    })

    await this.pushData({
      model: 'Payment',
      fields: { id: this.payment.id, id_process_status: status[0].id, id_closing_reason: closingReason[0].id },

    })
    this.paymentAlert.open = false
    await this.close()
  }
  }
